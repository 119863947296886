import React, { useState, useMemo } from 'react';
import { isLocalhost } from '../helpers/env-checkers';
import { getAppConfigFromQS } from '../helpers/get-app-config-from-qs';

export enum CustomerIds {
  'mesh' = 'mesh',
  'houzz' = 'houzz',
  'houzz_sandbox' = 'houzz_sandbox',
  'houzz_mesh_spoof' = 'houzz_mesh_spoof',
  'lisap' = 'lisap',
  'mesh_sandbox' = 'mesh_sandbox',
  'freemodel' = 'freemodel',
  'freemodel_sandbox' = 'freemodel_sandbox',
  'alhadeff_group' = 'alhadeff_group',
  'alhadeff_group_sandbox' = 'alhadeff_group_sandbox',
  'circle' = 'circle',
  'directory_for_contractors' = 'directory_for_contractors',
  'simpli_vetted' = 'simpli_vetted',
}
// We define our type for the context properties right here
type IAppContext = {
  origin: string;
  meshUserId: string;
  pathname: string | null;
  isVerifyMode: boolean;
  isProBeauty: boolean;
  customerId: CustomerIds;
  clientPreDefinedMail: string | null | undefined;
  userExists: boolean;
  setClientPreDefinedMail: (b: string | null | undefined) => void;
  setUserExists: (b: boolean) => void;
  setIsProBeauty: (b: boolean) => void;
  setCustomerId: (b: CustomerIds) => void;
};

export const AppContext = React.createContext<IAppContext>({} as IAppContext);

export const AppContextProvider: React.FC<{}> = ({ children }) => {
  const [state] = useState<{
    origin: string;
    meshUserId: string;
    pathname: string | null;
    isVerifyMode: boolean;
  }>(() => {
    const { origin, meshUserId, pathname } = getAppConfigFromQS();
    return {
      origin: origin as string,
      meshUserId: meshUserId as string,
      pathname,
      isVerifyMode: !!meshUserId,
    };
  });

  const [isProBeauty, setIsProBeauty] = useState(false);
  const [customerId, setCustomerId] = useState<CustomerIds>(CustomerIds.mesh);
  const [userExists, setUserExists] = useState<boolean>(false);
  const [clientPreDefinedMail, setClientPreDefinedMail] = useState<
    string | null | undefined
  >(null);

  const contextState = useMemo(() => {
    if (isLocalhost()) {
      console.log({
        ...state,
        isProBeauty,
        customerId,
        clientPreDefinedMail,
        userExists,
      });
    }
    return {
      ...state,
      isProBeauty,
      setIsProBeauty,
      customerId,
      setCustomerId,
      clientPreDefinedMail,
      setClientPreDefinedMail,
      userExists,
      setUserExists,
    };
  }, [
    state,
    isProBeauty,
    setIsProBeauty,
    customerId,
    setCustomerId,
    clientPreDefinedMail,
    setClientPreDefinedMail,
    userExists,
    setUserExists,
  ]);

  return (
    <AppContext.Provider value={contextState}>{children}</AppContext.Provider>
  );
};
