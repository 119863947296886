export enum ReactAppEnv {
  local = 'local',
  stg = 'stg',
  dev = 'dev',
  prod = 'prod',
}

export const isLocalhost = () => {
  return (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  );
};

export const isLocalEnv = () => {
  return process.env.REACT_APP_ENV === ReactAppEnv.local;
};

export const isStgEnv = () => {
  return process.env.REACT_APP_ENV === ReactAppEnv.stg;
};

export const isDevEnv = () => {
  return process.env.REACT_APP_ENV === ReactAppEnv.dev;
};

export const isProdEnv = () => {
  return process.env.REACT_APP_ENV === ReactAppEnv.prod;
};

export const getAppEnv = (): ReactAppEnv => {
  return process.env.REACT_APP_ENV as ReactAppEnv;
};
