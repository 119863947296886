import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import { ReactComponent as IICon } from '../../icons/info-solid.svg';
import styles from './styles.module.scss';

interface Props {
  text: string;
  position?: Placement;
  width?: number;
}

export const LabelTooltip: React.FC<Props> = ({ text, position, width }) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body style={{ width: width ? `${width}px` : undefined }}>
        {text}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={position || 'right'}
      overlay={popover}
    >
      <div className={styles.labelTooltipIconWrapper}>
        <IICon />
      </div>
    </OverlayTrigger>
  );
};
