
import { useState, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { magic } from '../lib/magic';
import { routes } from '../routes/routes';
import { UserContext } from '../context/user-context/user-context';
import Layout from '../components/layout/layout';
import { MeshService } from '../services/mesh-service.http';
import Header from '../components/header/header';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { RPCError, RPCErrorCode } from 'magic-sdk';
import { getQueryParam } from '../helpers/get-query-param';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppContext } from '../context/app-context';
import { setRefreshToken, setAccessToken } from '../helpers/jwt-helpers';
import classNames from 'classnames';
import { AxiosError } from 'axios';
import { captureError, captureMessage } from '../lib/sentry';
import { logEvent } from '../lib/analytics';

export const LoginPage = () => {
  const history = useHistory();
  const location = useLocation<{ errorMsg?: string }>();
  const [disabled, setDisabled] = useState(false);
  const { setUser, setDidToken } = useContext(UserContext);
  const {
    clientPreDefinedMail,
    isVerifyMode,
    userExists: preUserExists,
    meshUserId,
  } = useContext(AppContext);
  const [email, setEmail] = useState(clientPreDefinedMail || '');
  const [error, setError] = useState<string | null>(() => {
    if (location?.state?.errorMsg) {
      return location?.state?.errorMsg;
    }
    return null;
  });
  const setGenericError = () => {
    setError(
      'Something went wrong with logging in, please try again or contact support@mesh.id'
    );
  };

  const redirectPage = useMemo(() => {
    const redirect = getQueryParam('redirect');
    const isForSubscribe =
      redirect === 'subscribe' || redirect === 'unsubscribe';
    return isForSubscribe ? `/${redirect}` : routes.formPage();
  }, []);

  const onSubmit = async () => {
    setError(null);
    setDisabled(true); // disable login button to prevent multiple emails from being triggered
    let userExists: boolean;
    if (isVerifyMode && email === clientPreDefinedMail) {
      userExists = preUserExists;
    } else {
      try {
        userExists = await MeshService.userExists({ email });

      } catch (err) {
        captureError(err);
        setGenericError();
        setDisabled(false);
        return;
      }
    }

    if (!userExists && isVerifyMode) {
      logEvent('logged_in', { branch: 'first_time_email' })
      // If user does not exists and is verify mode
      setUser({ email: email });
      history.push(routes.formPage());
      return;
    }
    logEvent('logged_in', { branch: 'returning_email' })
    magic.auth
      .loginWithMagicLink({ email })
      .then((didToken) => {
        if (!didToken) throw new Error('No Token from the magic link');
        setDidToken(didToken);
        return MeshService.validateMagicLogin({ didToken });
      })
      .then(async ({ accessToken, refreshToken }) => {
        let userData = await magic.user.getMetadata();
        setUser({ ...userData, email: userData.email as string });
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);

        history.push(redirectPage);
      })
      .catch((err) => {
        if (
          (err as AxiosError)?.response?.data?.message ===
          'user email does not match email passed in by customer'
        ) {
          setError(
            'User email does not match email passed in by customer, please contact support@mesh.id'
          );
          return;
        }
        captureMessage(err);
        setDisabled(false);
        if (err instanceof RPCError) {
          switch (err.code) {
            case RPCErrorCode.UserAlreadyLoggedIn: {
              history.push('/');
              return;
            }
            case RPCErrorCode.MagicLinkExpired:
              setError('Your session has expired. Please refresh the form.');
              break;
            case RPCErrorCode.MagicLinkRateLimited:
              setGenericError();
              break;
            case RPCErrorCode.MagicLinkFailedVerification:
              setError('Verification failed. Please try again!');
              break;
            case -10011: // is not documented, code for when user will close the mesh verification
            case -10005: // User requests to edit the email address for authentication.
              break;
            default: {
              setError(err.message);
            }
          }
          return;
        }
        setGenericError();
      });
  };

  if (redirectPage === routes.formPage() && !meshUserId) {
    return null;
  }
  return (
    <Layout>
      <Header>
        {clientPreDefinedMail
          ? 'Confirm your email address'
          : 'Provide your email address'}
      </Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            disabled={!!clientPreDefinedMail}
            required
            onChange={(e) => {
              if (!!clientPreDefinedMail) return;
              setEmail(e.target.value);
            }}
            value={email}
            type="email"
            placeholder="Enter email"
            className={classNames({
              'cursor-disabled': !!clientPreDefinedMail,
            })}
          />
          <div className="mt-2">
            <Form.Text>
              This email address will be used by Mesh for important
              notifications about your license and to help you maintain a
              Verified License status
            </Form.Text>
          </div>
        </Form.Group>
        <div className="d-grid">
          <Button
            className="position-relative"
            disabled={disabled}
            variant="mesh"
            size="lg"
            type="submit"
          >
            {disabled && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                  color: '#f9fafb',
                }}
              />
            )}
            Continue
          </Button>
        </div>
      </Form>
    </Layout>
  );
};
