import React, { useContext } from 'react';
import { AppContext, CustomerIds } from '../../../context/app-context';
import {
  ExitPageStatuses,
  notifyParentOnFinish,
} from '../../../helpers/notify-parent';
import { useIsStandaloneWebpage } from '../../../hooks/use-is-standalone-webpage';
import Feedback from '../../feedback/feedback';
import styles from './styles.module.scss';

interface Props {
  feedbackParams: any;
}
export const Congratulations: React.FC<Props> = ({ feedbackParams }) => {
  const { origin, customerId } = useContext(AppContext);
  const isStandaloneWebsite = useIsStandaloneWebpage();
  return (
    <div className={styles.root}>
      <div className={styles.gif}>
        <img src="/images/congrats.gif" alt="" />
      </div>

      <h4 className={styles.title}>Congratulations!</h4>
      {[
        CustomerIds.houzz,
        CustomerIds.houzz_sandbox,
        CustomerIds.houzz_mesh_spoof,
      ].includes(customerId) ? (
        <>
          <p className={styles.text}>
            Your license has been verified. Soon, your business listing will
            reflect a new badge of trust for everyone to see.
          </p>
        </>
      ) : (
        <>
          <p className={styles.text}>Your license has been verified.</p>
        </>
      )}
      <Feedback initParams={feedbackParams}
        onSkipFeedback={isStandaloneWebsite ? null : () =>
          notifyParentOnFinish({
            exitPage: ExitPageStatuses.license_found_and_active,
            origin,
            payload: {},
          })
        }
        onSubmitFeedback={isStandaloneWebsite
          ? () => { }
          : () => {
            notifyParentOnFinish({
              exitPage: ExitPageStatuses.license_found_and_active,
              origin,
              payload: {},
            })
          }} />
    </div>
  );
};
