import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';

export const Loading: React.FC<{
  loaderProps?: CircularProgressProps;
}> = ({ loaderProps = {} }) => (
  <div style={{ textAlign: 'center' }}>
    <CircularProgress {...loaderProps} color="primary" />
  </div>
);
