import React, { useContext } from 'react';
import { AppContext, CustomerIds } from '../../../context/app-context';
import {
  ExitPageStatuses,
  notifyParentOnFinish,
} from '../../../helpers/notify-parent';

import styles from './styles.module.scss';
import sharedStyles from '../styles.module.scss';
import { useIsStandaloneWebpage } from '../../../hooks/use-is-standalone-webpage';
import { useDisplayName } from '../../../hooks/use-company-display-name';
import Feedback from '../../feedback/feedback';

interface Props {
  state: string;
  licenseType: string;
  feedbackParams: any;
}
export const StateNotSupported: React.FC<Props> = ({ state, licenseType, feedbackParams }) => {
  const { origin, customerId } = useContext(AppContext);
  const isStandaloneWebsite = useIsStandaloneWebpage();
  const displayName = useDisplayName()
  return (
    <div className={`${sharedStyles.root} ${styles.root}`}>
      <h4 className={sharedStyles.title}>Thanks for your interest</h4>
      {[
        CustomerIds.houzz,
        CustomerIds.houzz_sandbox,
        CustomerIds.houzz_mesh_spoof,
      ].includes(customerId) ? (
        <p className={`${sharedStyles.subtitle} ${styles.subTitle}`}>
          Thank you for submitting your information. Verified License is a brand
          new program, and it’s not available yet for {licenseType} licenses in{' '}
          {state}.
          <br />
          <br />
          We will notify you as soon as you’re eligible for a Verified License
          badge on Houzz.
        </p>
      ) : (
        <p className={`${sharedStyles.subtitle} ${styles.subTitle}`}>
          Thank you for submitting your information. At this time, license
          verifications on {displayName} are not available
          yet for {licenseType} licenses in {state}.
          <br />
          <br />
          We will notify you as soon as you're eligible to have your license
          verified for {displayName}.
        </p>
      )}
      <Feedback initParams={feedbackParams}
        onSkipFeedback={isStandaloneWebsite ? null : () =>
          notifyParentOnFinish({
            exitPage: ExitPageStatuses.state_not_supported,
            origin,
            payload: {},
          })
        }
        onSubmitFeedback={isStandaloneWebsite
          ? () => { }
          : () => {
            notifyParentOnFinish({
              exitPage: ExitPageStatuses.state_not_supported,
              origin,
              payload: {},
            })
          }} />
      <p className={`${sharedStyles.text} ${styles.text}`}>
        Need help? Contact us at{' '}
        <a className={sharedStyles.dark} href="mailto:support@mesh.id">
          support@mesh.id
        </a>
      </p>
    </div>
  );
};
