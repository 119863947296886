import { v4 as uuid } from 'uuid'

import SplitIO from '@splitsoftware/splitio-react/types/splitio/splitio';


export const splitConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_API_KEY as string,
    key: uuid(),
  }
};
