import React, { useContext, useEffect } from 'react';
import { useHeaderLogo } from '../../../hooks/use-header-logo';
import styles from './styles.module.scss';

export const StandAloneWrapper: React.FC<{ footer?: boolean }> = ({
  children,
}) => {
  useEffect(() => {
    document.body.classList.add(styles.bodyBg);
  }, []);
  const headerLogo = useHeaderLogo()
  return (
    <div className={styles.container}>
      {headerLogo && (<div className={styles.logo}>
        <img src={headerLogo} alt="" />
      </div>)}

      <div className={styles.inner}>{children}</div>
    </div>
  );
};
