import * as SentryBrowser from '@sentry/browser';
import { Severity } from '@sentry/types';
import Axios from 'axios';

export { SentryBrowser };

export const captureError = (
  err: any,
  captureContext:
    | { extra?: Record<string, any>; level?: Severity }
    | undefined = {}
) => {
  SentryBrowser.captureException(err, {
    ...captureContext,
    extra: {
      ...(captureContext?.extra || {}),
      ...extractAxiosErrorInfo(err),
    },
  });
};

export const captureMessage = (
  err: any,
  captureContext: { extra?: Record<string, any> } | undefined = {}
) => {
  SentryBrowser.captureMessage(err, {
    level: Severity.Info,
    ...captureContext,
    extra: {
      ...(captureContext?.extra || {}),
      ...extractAxiosErrorInfo(err),
    },
  });
};

const extractAxiosErrorInfo = (error: any): Record<string, any> => {
  if (!Axios.isAxiosError(error)) return {};
  const request = {
    url: error?.config?.url,
    method: error?.config?.method,
    params: error?.config?.params,
    data: error?.config?.data,
  };
  const response = {
    data: JSON.stringify(error?.response?.data),
    status: error?.response?.status,
  };
  return {
    request,
    response,
  };
};
