export const MeshHost = process.env.REACT_APP_MESH_HOST;

export const backEndRoutes = {
  users: {
    // GET
    exists: () => `${MeshHost}/users/exists`,
    // POST
    create: () => `${MeshHost}/users`,
    // GET
    get: () => `${MeshHost}/users`,
    // PUT
    unsubscribe: () => `${MeshHost}/users/email_subscription`,
    // POST
    logout: () => `${MeshHost}/logout`,
  },
  // GET
  getMeshUser: (meshUserId: string) =>
    `${MeshHost}/customers_mesh_users/${meshUserId}`,
  // POST
  refreshToken: () => `${MeshHost}/refresh`,
  // POST
  licensesVerify: () => `${MeshHost}/licenses/verification`,
  // GET
  checkLicenseStatus: (verification_id: string) =>
    `${MeshHost}/licenses/verification/${verification_id}`,
  // POST
  meshLogin: () => `${MeshHost}/login/validate`,
  // PUT
  unsubscribe: () =>
    `${MeshHost}/licenses/verification_session/verification/email_opt_out`,
};
