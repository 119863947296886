import ReactGA from 'react-ga';
import amplitude from 'amplitude-js';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ReactAppEnv, getAppEnv } from '../helpers/env-checkers';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { isInIframe } from '../helpers/is-in-iframe';

// TODO: temp before fix amplify deployment script
//  to use correct envs https://meshid.atlassian.net/browse/DEV-1731
const shouldInitializeAnalytics =
  [
    ReactAppEnv.dev,
    ReactAppEnv.prod,
    ReactAppEnv.stg,
    // ReactAppEnv.local,
  ].indexOf(getAppEnv()) !== -1 || process.env.NODE_ENV === 'production';

export const initializeAnalytics = () => {
  if (!shouldInitializeAnalytics) {
    return;
  }
  ReactGA.initialize('UA-000000-01');
  ReactGA.pageview(window.location.pathname + window.location.search);

  Sentry.init({
    dsn: 'https://30022fa3138445b4b23a74d21d990d86@o420827.ingest.sentry.io/5972534',
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: getAppEnv() || 'stg',
  });

  amplitude.getInstance().init(String(process.env.REACT_APP_AMPLITUDE_API_KEY));
  addProperties({ view_type: isInIframe() ? 'iframe' : 'stand-alone' })
}

let extraProperties = {}
export const addProperties = (properties: object) => {
  extraProperties = {
    ...extraProperties,
    ...properties,
  }
}
export const identifyUser = (userId: string) => {
  amplitude.getInstance().setUserId(userId);

}
export const logEvent = (event: string, eventProperties = {}) => {
  if (!shouldInitializeAnalytics) {
    return;
  }
  amplitude.getInstance().logEvent(event, { ...extraProperties, ...eventProperties });
}
