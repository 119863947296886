import React from 'react';
import { useToggle } from '../../../hooks/use-toggle';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../icons/minus.svg';

export const Accordion: React.FC<{ title: string }> = (props) => {
  const { title } = props;

  const { toggle, isActive: isOpen } = useToggle(false);

  return (
    <div className={styles['accordion']}>
      <div className={styles['accordion-title']} onClick={toggle}>
        <span>{title}</span>

        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      <div
        className={classnames(styles['accordion-content'], {
          [styles.open]: isOpen,
        })}
      >
        {props.children}
      </div>
    </div>
  );
};
