import React, { useState } from 'react';
import { FormControlProps } from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import { FieldError } from 'react-hook-form/dist/types/errors';
import { LabelTooltip } from './label-tooltip';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface BaseProps {
  type?: FormControlProps['type'];
  label: string;
  error: FieldError | undefined;
  value: string | undefined;
  placeholder?: string;
  onChange: (e: any) => void;
  labelTooltip?: string;
  inputTextLight?: boolean;
  rightPlaceholder?: string;
  min?: string | number;
}

export const FormControl: React.FC<BaseProps> = ({
  value,
  onChange,
  error,
  label,
  type,
  placeholder,
  labelTooltip,
  inputTextLight = true,
  rightPlaceholder,
  min,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const handleChange = (e: any) => {
    onChange(e);
    setInputValue(e.target.value);
  };
  return (
    <Form.Group className="mb-3">
      <Form.Label className="d-flex">
        {label}
        {labelTooltip && <LabelTooltip text={labelTooltip} />}
      </Form.Label>
      <div className="position-relative">
        <Form.Control
          className={classnames({ [styles.inputTextLight]: inputTextLight })}
          type={type}
          value={value as string}
          onChange={handleChange}
          placeholder={placeholder}
          isInvalid={!!error}
          min={min}
        />
        {rightPlaceholder && !inputValue && (
          <span className={styles.rightPlaceholder}>{rightPlaceholder}</span>
        )}
        <Form.Control.Feedback
          className={styles.invalidFeedback}
          type="invalid"
        >
          {error && error.message}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
};

interface SelectProps extends BaseProps {
  items: { label: string; value: string | number; id?: number | string }[];
}

export const FormControlSelect: React.FC<SelectProps> = ({
  value,
  onChange,
  error,
  label,
  placeholder,
  items,
  labelTooltip,
}) => (
  <Form.Group className="mb-3">
    <Form.Label className="d-flex">
      {label}
      {labelTooltip && <LabelTooltip text={labelTooltip} />}
    </Form.Label>
    <Form.Select
      isInvalid={!!error}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    >
      <option value="">Choose the option</option>
      {items.map((item) => (
        <option key={item.id || item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Form.Select>

    <Form.Control.Feedback type="invalid">
      {error && error.message}
    </Form.Control.Feedback>
  </Form.Group>
);

interface RadioProps extends BaseProps {
  items: { label: string; value: string | number; id?: number | string }[];
}

export const FormControlRadio: React.FC<RadioProps> = ({
  value,
  onChange,
  error,
  label,
  placeholder,
  items,
  labelTooltip,
}) => (
  <Form.Group className="mb-3">
    <Form.Label className="d-flex">
      {label}
      {labelTooltip && <LabelTooltip text={labelTooltip} />}
    </Form.Label>
    {items.map((item) => (
      <Form.Check
        className="px-4"
        id={`radio-${item.value}-${item.id}`}
        key={`radio-${item.value}-${item.id}`}
        type={'radio'}
        inline
        checked={item.value === value}
        isInvalid={!!error}
        placeholder={placeholder}
        onChange={onChange}
        label={item.label}
        value={item.value}
      />
    ))}
    <Form.Control.Feedback type="invalid">
      {error && error.message}
    </Form.Control.Feedback>
  </Form.Group>
);
