import Card from 'react-bootstrap/Card';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../../context/user-context/user-context';
import { routes } from '../../routes/routes';
import Layout from '../../components/layout/layout';
import { EmailSubscribeService } from '../../services/email-subscribe-service.http';
import { Alert } from 'react-bootstrap';
import { Loading } from '../../components/loading';
import styles from './styles.module.scss';
import { captureError } from '../../lib/sentry';

export const SubscribePage = () => {
  const { didToken } = useContext(UserContext);

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!didToken) return;
    EmailSubscribeService.subscribe({ didToken: didToken })
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        captureError(err);
        setIsLoading(false);
        setError(
          'Something went wrong with unsubscribing, please try again or contact support@mesh.id'
        );
      });
  }, [didToken]);

  useEffect(() => {
    if (!didToken) {
      history.push(routes.pageResolver() + '?redirect=subscribe');
    }
  }, [didToken, history]);
  if (!didToken) return null;

  const loader = (
    <div className="loader-page-wrapper">
      {error ? <Alert variant="danger">{error}</Alert> : <Loading />}
    </div>
  );

  return (
    <Layout>
      {isLoading || error ? (
        loader
      ) : (
        <div className={styles.container}>
          <Card>
            <Card.Body>
              <Card.Title as="h2">Thank you</Card.Title>
              <Card.Text>You have been Subscribed to the mail list!</Card.Text>
            </Card.Body>
          </Card>
        </div>
      )}
    </Layout>
  );
};
