import { useContext } from 'react';
import { AppContext, CustomerIds } from '../context/app-context';
import { isInIframe } from '../helpers/is-in-iframe';

const standAlonePageClients = [
  CustomerIds.freemodel, CustomerIds.freemodel_sandbox,
  CustomerIds.alhadeff_group, CustomerIds.alhadeff_group_sandbox,
  CustomerIds.circle, CustomerIds.directory_for_contractors,
  CustomerIds.simpli_vetted,
];


export const useIsStandaloneWebpage = () => {
  const { customerId } = useContext(AppContext);

  return standAlonePageClients.includes(customerId) && !isInIframe();
};
