import React, { useContext } from 'react';
import { AppContext } from '../../../context/app-context';
import { getStateNameForCode } from '../../../data/state-data';
import {
  ExitPageStatuses,
  notifyParentOnFinish,
} from '../../../helpers/notify-parent';
import { useIsStandaloneWebpage } from '../../../hooks/use-is-standalone-webpage';
import { IServerResult } from '../../../pages/form/types';
import { Feedback } from '../../feedback/feedback';
import { LicenseInfo } from '../../license-info/license-info';
import sharedStyles from '../styles.module.scss';
import pendingStyles from './pending.module.scss';


interface Props {
  license: Partial<IServerResult['found_license']['license']>;
  feedbackParams: any;
}
export const PendingScreen: React.FC<Props> = ({ license, feedbackParams }) => {
  const { origin } = useContext(AppContext);
  const isStandaloneWebsite = useIsStandaloneWebpage();

  return (
    <div className={`${sharedStyles.root} ${pendingStyles.root}`}>
      <h4 className={sharedStyles.title}>Thank You!</h4>
      <p className={sharedStyles.subTitle}>
        Your license is being reviewed, and it may take one business day to
        process.
      </p>

      <LicenseInfo
        title={
          <div className={pendingStyles.dataTitle}>
            In the meantime, please make sure the details submitted for
            verification are correct:
          </div>
        }
        data={[
          { key: 'License #', value: license?.license_number as string },
          {
            key: 'State',
            value: getStateNameForCode(license?.license_board_state as string),
          },
          {
            key: 'License Type',
            value: license?.license_type as string,
          },
        ]}
      />
      <Feedback initParams={feedbackParams}
        onSkipFeedback={isStandaloneWebsite ? null : () =>
          notifyParentOnFinish({
            exitPage: ExitPageStatuses.pending,
            origin,
            payload: {},
          })
        }
        onSubmitFeedback={isStandaloneWebsite
          ? () => { }
          : () => {
            notifyParentOnFinish({
              exitPage: ExitPageStatuses.pending,
              origin,
              payload: {},
            })
          }} />

      <p className={`${sharedStyles.text} ${pendingStyles.text}`}>
        Need help? Contact us at{' '}
        <a className={sharedStyles.dark} href="mailto:support@mesh.id">
          support@mesh.id
        </a>
      </p>
    </div>
  );
};
