import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/app-context';
import { MeshService } from '../../services/mesh-service.http';
import { Loading } from '../../components/loading';
import Layout from '../../components/layout/layout';
import { Alert } from 'react-bootstrap';
import { useLoginHelpers } from '../../hooks/use-login-helpers';
import { useHistory } from 'react-router';
import { routes } from '../../routes/routes';
import { UserContext } from '../../context/user-context/user-context';
import Axios, { AxiosError } from 'axios';
import { captureError, captureMessage } from '../../lib/sentry';
import { addProperties, logEvent } from '../../lib/analytics';

export const ResolverPage: React.FC<{}> = () => {
  const {
    meshUserId,
    setIsProBeauty,
    setCustomerId,
    setClientPreDefinedMail,
    isVerifyMode,
    setUserExists,
  } = useContext(AppContext);

  const { setUser } = useContext(UserContext);

  const [error, setError] = useState<string | null>(null);
  const history = useHistory();
  const {
    redirectToLogin,
    isForSubscription,
    redirectToSuccessPage,
    tryLocalLogin,
  } = useLoginHelpers();

  useEffect(() => {
    (async function () {
      if (isForSubscription) {
        redirectToLogin();
        return;
      }
      if (!isVerifyMode) {
        setError('Mesh User Id does not exists');
        return;
      }

      let getMeshUserResult;
      try {
        getMeshUserResult = await MeshService.getMeshUser({ meshUserId });
      } catch (err) {
        if (
          Axios.isAxiosError(err) &&
          (err as AxiosError)?.response?.status === 404 &&
          (err as AxiosError)?.response?.data?.message === 'Mesh User Not Found'
        ) {
          setError('Mesh User Id is not valid');
          captureMessage(err);
        } else {
          setError('Unexpected error has occurred please refresh modal');
          captureError(err, {
            extra: {
              message: `Mesh User Id is not valid: ${meshUserId}`,
            },
          });
        }
        return;
      }
      const {
        license_category_ids,
        customer_id,
        user_exists,
        email: clientPreDefinedMail,
      } = getMeshUserResult;

      setCustomerId(customer_id);
      addProperties({ customer_id })
      logEvent('page_loaded')
      setClientPreDefinedMail(clientPreDefinedMail);
      setUserExists(user_exists);

      if (
        Array.isArray(license_category_ids) &&
        license_category_ids.indexOf('professional_beauty') !== -1
      ) {
        setIsProBeauty(true);
      }

      const wasSuccessFull = await tryLocalLogin(clientPreDefinedMail);
      if (wasSuccessFull) {
        redirectToSuccessPage();
        return;
      }

      if (clientPreDefinedMail && !user_exists) {
        setUser({ email: clientPreDefinedMail });
        logEvent('logged_in', { branch: 'predefined_email' })
        history.push(routes.formPage());
        return;
      }

      history.push(routes.login());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout footer={false}>
      <div className="loader-page-wrapper">
        {error ? <Alert variant="danger">{error}</Alert> : <Loading />}
      </div>
    </Layout>
  );
};
