import jwt_decode from 'jwt-decode';
import {
  setMeshAuthTokenOnAxios,
  deleteMeshAuthTokenFromAxios,
} from '../services/axios-helpers';

import {
  getFromLocalStorage,
  LocalStorageKeys,
  removeFromLocalStorage,
  setInLocalStorage,
} from './local-storage-helpers';

export const isJwtTokenExpired = (token: string) => {
  try {
    const decoded = jwt_decode<{ exp: number }>(token);
    if (decoded && decoded?.exp) {
      if (decoded?.exp * 1000 > Date.now()) {
        return false;
      }
    }
  } catch (err) {}
  return true;
};

export const getAccessToken = () => {
  const token = getFromLocalStorage(LocalStorageKeys.meshAccessToken);
  if (token) return token;
  return null;
};
export const getRefreshToken = () => {
  const token = getFromLocalStorage(LocalStorageKeys.meshRefreshToken);
  if (token) return token;
  return null;
};

export const setAccessToken = (accessToken: string | null) => {
  if (accessToken) {
    setMeshAuthTokenOnAxios(accessToken);
    setInLocalStorage(LocalStorageKeys.meshAccessToken, accessToken);
  } else {
    deleteMeshAuthTokenFromAxios();
    removeFromLocalStorage(LocalStorageKeys.meshAccessToken);
  }
};

export const setRefreshToken = (refreshToken: string) => {
  if (refreshToken) {
    setInLocalStorage(LocalStorageKeys.meshRefreshToken, refreshToken);
  } else {
    removeFromLocalStorage(LocalStorageKeys.meshRefreshToken);
  }
};

export const deleteJwtTokens = () => {
  removeFromLocalStorage(LocalStorageKeys.meshRefreshToken);
  removeFromLocalStorage(LocalStorageKeys.meshAccessToken);
  deleteMeshAuthTokenFromAxios();
};
