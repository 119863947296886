import styles from './feedback.module.scss';
import React, { useEffect, useState, useContext } from "react";
export const UsersnapContext = React.createContext(null);
declare global {
  interface Window { onUsersnapCXLoad: any; }
}

window.onUsersnapCXLoad = window.onUsersnapCXLoad || {};

interface Props {
  initParams: any;
  onSkipFeedback: null | (() => void)
  onSubmitFeedback: () => void
}

export const Feedback: React.FC<Props> = ({ initParams = {}, onSkipFeedback, onSubmitFeedback }) => {
  const [usersnapApi, setUsersnapApi] = useState(null);
  useEffect(() => {
    let usersnapApi: any = null
    window.onUsersnapCXLoad = function (api: any) {
      api.init({
        mountNode: document.getElementById('feedbackContainer'),
      });
      usersnapApi = api
      api.on('open', (event: any) => {
        event.api.setValue('visitor', initParams?.user?.email);
        event.api.setValue('custom', initParams?.custom);
      });
      api.on('submit', (event: any) => {
        onSubmitFeedback()
      });
      setUsersnapApi(api)
    }
    const script = document.createElement('script');
    script.defer = true;
    script.src = `https://widget.usersnap.com/embed/load/${process.env.REACT_APP_USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`;
    document.head.appendChild(script);

    return () => {
      if (usersnapApi && usersnapApi.destroy) {
        usersnapApi.destroy();
      }
      script.remove();
    }
  }, [])


  return (
    <React.Fragment>
      <div id='feedbackContainer' className={styles.feedbackContainer}>
        <UsersnapContext.Provider value={usersnapApi}></UsersnapContext.Provider>
      </div>
      {onSkipFeedback && (
        <div className="text-center">
          <a href='#' onClick={onSkipFeedback} >
            Skip feedback
          </a>
        </div>
      )}
    </React.Fragment >
  )
}
export default Feedback;

export function useUsersnapApi() {
  return useContext(UsersnapContext)
}
