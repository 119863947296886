import classNames from 'classnames';
import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { AppContext } from '../../../context/app-context';
import {
  ExitPageStatuses,
  notifyParentOnFinish,
} from '../../../helpers/notify-parent';
import { useIsStandaloneWebpage } from '../../../hooks/use-is-standalone-webpage';
import { LicenseInfo } from '../../license-info/license-info';

import styles from '../styles.module.scss';
import pageStyles from './styles.module.scss';

interface Props {
  submitNew: () => void;
  license: {
    state: string;
    license_number: string;
    first_name: string;
    last_name: string;
    license_type: string;
  };
}
export const LicenseNotFound: React.FC<Props> = ({ submitNew, license }) => {
  const { origin } = useContext(AppContext);

  const getPersonsFullName = () => {
    let fullName = license?.first_name || '';
    fullName += license?.last_name ? ` ${license.last_name}` : '';
    return fullName.trim();
  };
  const isStandaloneWebsite = useIsStandaloneWebpage();
  return (
    <div className={classNames(styles.root, pageStyles.root)}>
      <h4 className={styles.title}>Unable to verify your license</h4>

      <div className={styles.subTitle}>
        Based on the information you’ve provided, we were unable to verify your
        professional license. This may be due to:
      </div>

      <ul className={pageStyles.list}>
        {[
          'A missing digit, dash, dot, prefix, or suffix in your license number',
          'Incorrect license category or issuing state',
          'The license may be inactive',
        ].map((text) => (
          <li>{text}</li>
        ))}
      </ul>

      <LicenseInfo
        title="Information you provided for verification"
        data={[
          { key: 'License #', value: license?.license_number },
          { key: 'State', value: license?.state },
          { key: 'License Type', value: license?.license_type },
          { key: 'Name', value: getPersonsFullName() },
        ].filter((item) => !!item.value)}
      />

      <p className={classNames(styles.text, pageStyles.text)}>
        Would you like to try submitting again?
      </p>

      {isStandaloneWebsite
        ? (<div className="text-center">
          <Button
            className={styles.button}
            variant="mesh"
            type="button"
            onClick={submitNew}
          >
            Yes, try again
          </Button>
        </div>
        )
        : (<div className={styles.btnWrapper}>
          <Button
            className={styles.button}
            variant="default"
            type="button"
            onClick={() => {
              notifyParentOnFinish({
                exitPage: ExitPageStatuses.license_not_found,
                origin,
                payload: {},
              });
            }}
          >
            No
          </Button>

          <Button
            className={styles.button}
            variant="mesh"
            type="button"
            onClick={submitNew}
          >
            Yes
          </Button>
        </div>)
      }
    </div>
  );
};
