import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  data: { key: string; value: string }[];
  title?: React.ReactNode | string;
}

export const LicenseInfo: React.FC<Props> = ({ data, title }) => {
  return (
    <div className={styles.container}>
      {title && typeof title !== 'string' ? (
        title
      ) : (
        <div className={classNames(styles.title)}>
          {title || 'Found license'}
        </div>
      )}

      <div className={styles.data}>
        {data.map((item) => (
          <div key={item.key} className={styles.item}>
            <div>{item.key}:</div> <div>{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
