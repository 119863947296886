import { backEndRoutes } from './backend-routes';
import { axiosWithMeshToken } from './axios-with-mesh-token';

export interface ILicense {
  expiration_date: string;
  first_name: string;
  last_name: string;
  license_board_state: string;
  license_category_id: string;
  license_number: string;
  entity_type: string;
}

export interface IVerifyParams {
  license_category_id: string;
  license_board_state: string;
  license_type: string;
  license_number: string;
  additional_information: string | null;
  entity_type: string | null;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  business_name?: string | null;
  address: string | null;
  phone_number: string | null;
  expiration_date: Date | null | string;
  email: string | null;
  internal_id: string | null;
  monitor_config: {
    enabled: boolean;
    interval_days: number;
  } | null;
}

export interface IVerifyRes {
  entered_internal_review: boolean;
  failed_reason?: any;
  input: {
    expiration_date: string;
    first_name: string;
    last_name: string;
    license_board_state: string;
    license_category_id: string;
    license_number: string;
    entity_type: string;
  };
  internal_review_reason: string;
  result?: any;
  mesh_user_id: string;
  time_info: {
    finished_datetime?: any;
    requested_datetime: Date;
  };
  verification_id: string;
  verification_source: string;
  verification_status: 'pending' | 'finished' | 'failed';
}

class _LicensesService {
  verify = (data: IVerifyParams, headers: { meshUserId: string }) => {
    return axiosWithMeshToken.post<IVerifyRes>(
      backEndRoutes.licensesVerify(),
      data,
      {
        headers: {
          'x-mesh-user-id': headers.meshUserId,
        },
      }
    );
  };

  checkStatus = (verification_id: string, headers: { meshUserId: string }) => {
    return axiosWithMeshToken.get<IVerifyRes>(
      backEndRoutes.checkLicenseStatus(verification_id),
      {
        headers: {
          'x-mesh-user-id': headers.meshUserId,
        },
      }
    );
  };
}

export const LicensesService = new _LicensesService();
