import { axiosWithMeshToken } from './axios-with-mesh-token';
import { backEndRoutes } from './backend-routes';

class _EmailSubscribeService {
  unsubscribe = ({ didToken }: { didToken: string }) => {
    return axiosWithMeshToken.put(
      backEndRoutes.users.unsubscribe(),
      {
        email_opt_out: true,
      },
      {
        headers: {
          Authorization: `Bearer ${didToken}`,
        },
      }
    );
  };

  subscribe = ({ didToken }: { didToken: string }) => {
    return axiosWithMeshToken.put(
      backEndRoutes.users.unsubscribe(),
      {
        email_opt_out: false,
      },
      {
        headers: {
          Authorization: `Bearer ${didToken}`,
        },
      }
    );
  };
}

export const EmailSubscribeService = new _EmailSubscribeService();
