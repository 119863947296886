import React, { useContext, useEffect, useCallback, useMemo } from 'react';
import {
  Control,
  UseFormWatch,
  UseFormTrigger,
  UseFormClearErrors,
  UseFormSetValue,
} from 'react-hook-form';
import Alert from 'react-bootstrap/Alert';
import styles from './styles.module.scss';
import Header from '../../header/header';
import { IVerifyParams } from '../../../services/licenses-service.http';
import {
  getLicenseTypesForForm,
} from '../../../data/categories';
import { LicenseeEntityType } from './verify-form.typs';
import { AppContext } from '../../../context/app-context';
import { UserContext } from '../../../context/user-context/user-context';
import { LabelTooltip } from '../../form-control/label-tooltip';
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import { VerifyFormC } from './verify-form-c';
import { VerifyFormT1 } from './verify-form-t1';
import { Loading } from '../../loading';
import { addProperties, logEvent } from '../../../lib/analytics';


const splitExperimentName = 'OneClickVerify-Funnel-Fixes';
interface Props {
  serverErrorMsg: string | null | undefined;
  control: Control<IVerifyParams, object>;
  watch: UseFormWatch<IVerifyParams>;
  trigger: UseFormTrigger<IVerifyParams>;
  onSubmit: () => void;
  isSubmitDisabled: boolean;
  clearErrors: UseFormClearErrors<IVerifyParams>;
  setValue: UseFormSetValue<IVerifyParams>;
}

export const VerifyForm: React.FC<Props> = ({
  serverErrorMsg,
  control,
  watch,
  onSubmit,
  clearErrors,
  isSubmitDisabled,
  setValue,
}) => {
  const { isProBeauty, customerId } = useContext(AppContext);
  const { user } = useContext(UserContext);
  const entityType = watch('entity_type');

  useEffect(() => {
    if (entityType === LicenseeEntityType.business) {
      clearErrors('first_name');
      clearErrors('last_name');
    } else if (entityType === LicenseeEntityType.individual) {
      clearErrors('business_name');
    } else {
      clearErrors('first_name');
      clearErrors('last_name');
      clearErrors('business_name');
    }
  }, [entityType, clearErrors]);

  useEffect(() => {
    if (!isProBeauty) return;
    setValue('license_type', '');
  }, [entityType, isProBeauty, setValue]);

  const isIndividualNameRequired = useCallback(
    (value: string | null): boolean => {
      if (watch('entity_type') === LicenseeEntityType.business) return true;
      return !!value?.trim();
    },
    [watch]
  );

  const isBusinessNameRequired = useCallback(
    (value: string | null | undefined): boolean => {
      if (watch('entity_type') !== LicenseeEntityType.business) return true;
      return !!value?.trim();
    },
    [watch]
  );

  const getLicenseTypes = useMemo(() => {
    const licenseType = (entityType as LicenseeEntityType) || LicenseeEntityType.individual
    return getLicenseTypesForForm({ isProBeauty, customerId, licenseType }).map((c) => ({
      label: c.name,
      value: c.id,
    }))
  }, [isProBeauty, customerId, entityType]);
  const attributes = { customerId, email: user?.email }
  const { isReady } = useContext(SplitContext);
  const treatments = useTreatments([splitExperimentName], attributes);
  useEffect(() => {
    if (isReady) {
      const { treatment } = treatments[splitExperimentName]
      console.log('Adding metrics', treatment)
      addProperties({
        splitExperimentName: treatment,
      });
      logEvent('treatment-selected')
    }
  }, [isReady, treatments]);

  if (!isReady) {
    <Loading />
  }
  const SplitForm = VerifyFormT1
  return (
    <>
      <Header>
        Provide your license details exactly as they appear on your state-issued
        license
      </Header>
      <div>
        <div className={styles.yourEmail}>
          Your email: <span className={styles.email}>{user?.email}</span>
          <LabelTooltip
            width={131}
            position={'auto'}
            text={
              'This email address will be used by Mesh for important notifications about your license.'
            }
          />
        </div>
        {serverErrorMsg && <Alert variant="danger">{serverErrorMsg}</Alert>}
        <SplitForm
          control={control}
          getLicenseTypes={getLicenseTypes}
          isIndividualNameRequired={isIndividualNameRequired}
          isBusinessNameRequired={isBusinessNameRequired}
          watch={watch}
          isSubmitDisabled={isSubmitDisabled}
          onSubmit={onSubmit}
        />
      </div>
    </>
  );
};
