import React from 'react';
import ReactDOM from 'react-dom';
import './scss/app.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './context/app-context';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { UserContextProvider } from './context/user-context/user-context';
import { StylesProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { initializeAnalytics } from './lib/analytics';

initializeAnalytics()

ReactDOM.render(
  // <React.StrictMode>
  <Sentry.ErrorBoundary
    fallback={() => (
      <h3 className="text-center">
        Something went wrong, please contact support@mesh.id
      </h3>
    )}
  >
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <AppContextProvider>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </AppContextProvider>
      </StylesProvider>
    </ThemeProvider>
  </Sentry.ErrorBoundary>,
  // </React.StrictMode>
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
