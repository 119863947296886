import React, { useContext } from 'react';
import { useIsStandaloneWebpage } from '../../hooks/use-is-standalone-webpage';
import Footer from '../footer/footer';
import { StandAloneWrapper } from './stand-alone-wrapper/stand-alone-wrapper';
import styles from './styles.module.scss';

const Layout: React.FC<{ footer?: boolean }> = ({
  children,
  footer = true,
}) => {
  const isStandAloneWebpage = useIsStandaloneWebpage();

  const basicLayout = (
    <main className={styles.main}>
      <div className="app-container">{children}</div>
      {footer && <Footer />}
    </main>
  );

  if (isStandAloneWebpage) {
    return <StandAloneWrapper>{basicLayout}</StandAloneWrapper>;
  }
  return basicLayout;
};

export default Layout;
