import React, { useContext } from 'react';
import { withSplitFactory } from '@splitsoftware/splitio-react';
import './App.css';
import { splitConfig } from './split';

import { Switch, Route, BrowserRouter as Router, Link } from 'react-router-dom';
import { LoginPage } from './pages/login';
import { FormPage } from './pages/form/form-page';
import { routes } from './routes/routes';
import { ResolverPage } from './pages/resolver-page/resolver-page';
import { AppContext } from './context/app-context';
import { UnsubscribePage } from './pages/mail-subscriptions/unsubscribe';
import { SubscribePage } from './pages/mail-subscriptions/subscribe';
import { isInIframe } from './helpers/is-in-iframe';
import { getAccessToken, getRefreshToken } from './helpers/jwt-helpers';
import { setMeshAuthTokenOnAxios } from './services/axios-helpers';

const accessToken = getAccessToken();
if (accessToken && getRefreshToken()) {
  setMeshAuthTokenOnAxios(accessToken);
}

const MIGRATED_USERS = [
  'alhadeffgroupstg',
  'alhadeffgroup',
  'freemodelstg',
  'freemodel',
  'circle',
]
const MIGRATED_MESH_USER_IDS = {
  'c193f651-8e5f-4586-a2a8-9543bd34e832': 'alhadeffgroupstg',
  '968095ee-836e-4ff1-8562-c9595dbd6c0b': 'alhadeffgroup',
  'e0acf4cc-3636-4f34-a28b-03cde5cd9512': 'freemodelstg',
  '480c8c26-593e-4082-99c5-5b8ad908297d': 'freemodel',
  '95ded6c2-1dc5-49e9-b3e3-0bdc1ddb88bc': 'circle',
} as Record<string, string>

function App() {
  const { origin, meshUserId, pathname } = useContext(AppContext);
  if (pathname && MIGRATED_USERS.includes(pathname)) {
    const destination = `https://verify-ui.mesh.id/a/${pathname}/migration`
    console.log('Navigating to', destination)
    window.location.replace(destination)
  }
  if (Object.keys(MIGRATED_MESH_USER_IDS).includes(meshUserId)) {
    const destination = `https://verify-ui.mesh.id/a/${MIGRATED_MESH_USER_IDS[meshUserId]}/migration`
    console.log('Navigating to', destination)
    window.location.replace(destination)
  }

  if ((!origin || !meshUserId) && isInIframe()) {
    return <h1>Please Provide Origin and Mesh User Id</h1>;
  }

  return (
    <Router>
      <Switch>
        <Route path={routes.login()} exact component={LoginPage} />

        {/* TODO: add middleware for meshUserId */}
        {meshUserId && (
          <Route path={routes.formPage()} exact component={FormPage} />
        )}
        {/* TODO: add middleware for auth */}
        <Route path={routes.unsubscribe()} exact component={UnsubscribePage} />
        <Route path={routes.subscribe()} exact component={SubscribePage} />
        <Route path={routes.pageResolver()} component={ResolverPage} />
      </Switch>
    </Router>
  );
}

export default withSplitFactory(splitConfig)(App);
