import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { AppContext } from '../../../context/app-context';
import { getStateNameForCode } from '../../../data/state-data';
import {
  ExitPageStatuses,
  notifyParentOnFinish,
} from '../../../helpers/notify-parent';
import { capitalizeFirstLetter } from '../../../helpers/text-helpers';
import { formatLicenseTime } from '../../../helpers/time-format';
import { useIsStandaloneWebpage } from '../../../hooks/use-is-standalone-webpage';
import { IServerResult } from '../../../pages/form/types';
import { LicenseInfo } from '../../license-info/license-info';
import { Accordion } from '../../ui/accordion/accordion';

import styles from '../styles.module.scss';

interface Props {
  submitNew: () => void;
  license: IServerResult['found_license']['license'];
}
export const FoundAndInactiveScreen: React.FC<Props> = ({
  submitNew,
  license,
}) => {
  const { origin } = useContext(AppContext);

  const getPersonsFullName = () => {
    let fullName = license?.first_name || '';
    if (license?.middle_name) {
      fullName += ` ${license?.middle_name}`;
    }
    fullName += license?.last_name ? ` ${license.last_name}` : '';
    return fullName.trim();
  };
  const isStandaloneWebsite = useIsStandaloneWebpage();
  return (
    <div className={styles.root}>
      <h4 className={styles.title}>Your action is needed.</h4>

      <div className={styles.subTitle}>
        Based on government records, your license appears to be inactive.
      </div>

      <LicenseInfo
        data={[
          { key: 'License #', value: license?.license_number },
          {
            key: 'State',
            value: getStateNameForCode(license?.license_board_state as string),
          },
          { key: 'License Type', value: license?.license_type },
          {
            key: 'Business Name',
            value: license?.business_name,
          },
          {
            key: 'Individual Name',
            value: getPersonsFullName(),
          },
          {
            key: 'Status',
            value: capitalizeFirstLetter(license?.status_as_is),
          },
          {
            key: 'Expiration',
            value: formatLicenseTime(license?.expiration_date),
          },
        ].filter((item) => !!item.value)}
      />

      <div className="mt-3">
        <Accordion title="Think the above information is incorrect?">
          Please contact us within 5 days at{' '}
          <a href="mailto:support@mesh.id">support@mesh.id</a> including a
          picture of your professional license showing the details you submitted
          for verification, and we'll be happy to help.
        </Accordion>
      </div>

      <p className={styles.text}>
        Want to submit another professional license for verification?
      </p>

      {isStandaloneWebsite
        ? (<div className="text-center">
          <Button
            className={styles.button}
            variant="mesh"
            type="button"
            onClick={submitNew}
          >
            Yes, try again
          </Button>
        </div>
        )
        : (<div className={styles.btnWrapper}>
          <Button
            className={styles.button}
            variant="default"
            type="button"
            onClick={() => {
              notifyParentOnFinish({
                exitPage: ExitPageStatuses.license_inactive,
                origin,
                payload: {},
              });
            }}
          >
            No
          </Button>

          <Button
            className={styles.button}
            variant="mesh"
            type="button"
            onClick={submitNew}
          >
            Yes
          </Button>
        </div>)
      }
    </div>
  );
};
