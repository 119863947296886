export enum LocalStorageKeys {
  meshAccessToken = 'meshAccessToken',
  meshRefreshToken = 'meshRefreshToken',
}

export const setInLocalStorage = (key: LocalStorageKeys, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {}
};

export const removeFromLocalStorage = (key: LocalStorageKeys) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {}
};

export const getFromLocalStorage = (key: LocalStorageKeys) => {
  try {
    return localStorage.getItem(key);
  } catch (err) {
    return null;
  }
};
