import { createContext, useCallback, useMemo, useState } from 'react';
import { isLocalhost } from '../../helpers/env-checkers';
import { deleteJwtTokens, getRefreshToken } from '../../helpers/jwt-helpers';
import { MagicService } from '../../services/magic-service.http';
import { MeshService } from '../../services/mesh-service.http';

// type IUser = { id?: number | string; email: string };
export type IUser = Record<string, any> & { id?: number | string; email: string };

type IUserContext = {
  user: IUser | null;
  setUser: (user: IUser | null) => void;
  didToken: string | null;
  setDidToken: (t: string | null) => void;
  logout: () => void;
};

export const UserContext = createContext<IUserContext>({} as IUserContext);

// TODO: Merge State
export const UserContextProvider: React.FC<{}> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [didToken, setDidToken] = useState<string | null>(null);

  const logout = useCallback(() => {
    MagicService.logout().then().catch(console.error);
    const rT = getRefreshToken();
    if (rT) {
      MeshService.logout(rT).catch(console.error);
    }
    setUser(null);
    setDidToken(null);
    deleteJwtTokens();
  }, []);

  const contextState = useMemo(() => {
    if (isLocalhost()) {
      console.log({
        user,
        setUser,
        didToken,
        setDidToken,
        logout,
      });
    }
    return {
      user,
      setUser,
      didToken,
      setDidToken,
      logout,
    };
  }, [user, didToken, setDidToken, logout]);

  return (
    <UserContext.Provider value={contextState}>{children}</UserContext.Provider>
  );
};
