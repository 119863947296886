import React from 'react';
import Button from 'react-bootstrap/Button';
import {
  Control,
  Controller,
  UseFormWatch,
  UseFormTrigger,
  UseFormClearErrors,
  UseFormSetValue,
} from 'react-hook-form';
import styles from './styles.module.scss';
import { StatesData } from '../../../data/state-data';
import {
  FormControl,
  FormControlRadio,
  FormControlSelect,
} from '../../form-control/form-control';
import { IVerifyParams } from '../../../services/licenses-service.http';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { LicenseeEntityType } from './verify-form.typs';
import moment from 'moment';

interface Props {
  control: Control<IVerifyParams, object>;
  watch: UseFormWatch<IVerifyParams>;
  onSubmit: () => void;
  isSubmitDisabled: boolean;
  isIndividualNameRequired: (value: string | null) => boolean;
  isBusinessNameRequired: (value: string | null | undefined) => boolean;
  getLicenseTypes: {
    label: string;
    value: string;
  }[]
}

export const VerifyFormT1: React.FC<Props> = ({
  control,
  watch,
  onSubmit,
  isSubmitDisabled,
  isIndividualNameRequired,
  isBusinessNameRequired,
  getLicenseTypes,
}) =>
  <Container fluid className="gx-0">
    <Row className="gx-2">
      <Col xs={12} sm={12}>
        <Controller
          name="entity_type"
          control={control}
          defaultValue=""
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (

            <FormControlRadio
              label="Is your business name listed on your license?"
              labelTooltip="Is your professional license under a business name or an individual/person name?"
              value={value as string}
              onChange={onChange}
              error={error}
              items={[
                {
                  label: 'Yes',
                  value: LicenseeEntityType.business,
                },
                {
                  label: 'No',
                  value: LicenseeEntityType.individual,
                },
              ]}
            />
          )}
          rules={{ required: true }}
        />
      </Col>

      {watch('entity_type') === LicenseeEntityType.individual && (
        <React.Fragment>

        </React.Fragment>
      )}

      {watch('entity_type') === LicenseeEntityType.business && (
        <Col xs={12}>
          <Controller
            defaultValue=""
            name="business_name"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <FormControl
                labelTooltip="Enter your business name exactly as it appears on the government-issued license"
                label="Business Name"
                value={value as string}
                onChange={onChange}
                error={error}
              />
            )}
            rules={{
              validate: {
                required: isBusinessNameRequired,
              },
            }}
          />
        </Col>
      )}

      <Col xs={6}>
        <Controller
          defaultValue=""
          name="first_name"
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <FormControl
              labelTooltip="Enter your name exactly as it appears on the government-issued license"
              label={watch('entity_type') === LicenseeEntityType.business ? "First Name (Optional)" : "First Name"}
              value={value as string}
              onChange={onChange}
              error={error}
            />
          )}
          rules={{
            validate: {
              required: isIndividualNameRequired,
            },
          }}
        />
      </Col>
      <Col xs={6}>
        <Controller
          defaultValue=""
          name="last_name"
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <FormControl
              label={watch('entity_type') === LicenseeEntityType.business ? "Last Name (Optional)" : "Last Name"}
              value={value as string}
              onChange={onChange}
              error={error}
            />
          )}
          rules={{
            validate: {
              required: isIndividualNameRequired,
            },
          }}
        />
      </Col>

      <Col xs={12} sm={6}>
        <Controller
          name="license_type"
          defaultValue=""
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <FormControlSelect
              label="License Type"
              value={value as string}
              onChange={onChange}
              error={error}
              items={getLicenseTypes}
            />
          )}
          rules={{ required: true }}
        />
      </Col>
      <Col xs={12} sm={6}>
        <Controller
          name="license_board_state"
          defaultValue=""
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <FormControlSelect
              label="Issuing State"
              value={value as string}
              onChange={onChange}
              error={error}
              items={StatesData.map((item) => ({
                label: item.State,
                value: item.Code,
                id: item.Code,
              }))}
            />
          )}
          rules={{ required: true }}
        />
      </Col>


      <Col xs={12}>
        <Controller
          name="license_number"
          control={control}
          defaultValue=""
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <FormControl
              labelTooltip="Exactly as it appears on your license, including dots, dashes, preceding zeroes, and any prefix/suffix"
              label="License Number"
              value={value as string}
              onChange={onChange}
              error={error}
            />
          )}
          rules={{ required: true }}
        />
      </Col>
      <Col xs={12}>
        <div className="d-grid">
          <Button
            variant="mesh"
            type="button"
            disabled={isSubmitDisabled}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </Col>
      <Col xs={12}>
        <p className={classNames(styles['form-text-info'], 'mt-3')}>
          By submitting this form, I certify that I am the licensee above
          or I am authorized to act on behalf of the business/individual
          named on this license. I agree to being verified under Mesh's{' '}
          <a
            target="_blank"
            href="https://www.mesh.id/terms-of-use"
            rel="noreferrer"
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            target="_blank"
            href="https://www.mesh.id/privacy-policy"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          , and consent to Mesh contacting me with information relating to
          my license.
        </p>
      </Col>
    </Row>
  </Container>
