import { LicenseeEntityType } from '../components/form-result-screens/verify-form/verify-form.typs';
import { CustomerIds } from '../context/app-context';

interface GetLicenseTypesArgs {
  isProBeauty: boolean;
  customerId: CustomerIds;
  licenseType: LicenseeEntityType;
}

interface LicenseType {
  name: string;
  id: string
}

const otherLicenseType = {
  name: 'Others',
  id: 'others',
}

export const getLicenseTypesForForm = ({
  isProBeauty,
  customerId,
  licenseType,
}: GetLicenseTypesArgs): LicenseType[] => {
  if (isProBeauty) {
    return proBeautyLicenseTypes(licenseType)
  }
  const additionalLicenseTypes = additionalCustomerLicenseTypes[customerId] || []

  return [...defaultLicenseTypes, ...additionalLicenseTypes, otherLicenseType]
}


export const proBeautyLicenseTypes = (
  licenseType: LicenseeEntityType
): LicenseType[] => {
  if (licenseType === LicenseeEntityType.individual) {
    return [
      {
        name: 'Cosmetology',
        id: 'cosmetology',
      },
      {
        name: 'Barbering',
        id: 'barbering',
      },
      otherLicenseType,
    ];
  }
  return [
    {
      name: 'Salon',
      id: 'salon',
    },
    otherLicenseType,
  ];
};

export const defaultLicenseTypes: Readonly<{ name: string; id: string }[]> = [
  {
    name: 'General Contractors',
    id: 'general_contractors',
  },
  {
    name: 'Plumbing',
    id: 'plumbing',
  },
  {
    name: 'Electrician',
    id: 'electrician',
  },
  {
    name: 'Architecture',
    id: 'architecture',
  },
];

export const additionalCustomerLicenseTypes = {
  [CustomerIds.freemodel]: [],
  [CustomerIds.freemodel_sandbox]: [],
  [CustomerIds.alhadeff_group]: [],
  [CustomerIds.alhadeff_group_sandbox]: [],
  [CustomerIds.mesh]: [],
  [CustomerIds.houzz]: [],
  [CustomerIds.houzz_sandbox]: [],
  [CustomerIds.houzz_mesh_spoof]: [],
  [CustomerIds.lisap]: [],
  [CustomerIds.mesh_sandbox]: [],
  [CustomerIds.circle]: [],
  [CustomerIds.directory_for_contractors]: [
    {
      name: 'Landscapers',
      id: 'landscapers',
    },
    {
      name: 'Trades Contractors',
      id: 'trades_contractors',
    },
    {
      name: 'Home Inspectors',
      id: 'home_inspectors',
    },
    {
      name: 'Structural Engineers',
      id: 'structural_engineers',
    },
    {
      name: 'Pest Control',
      id: 'pest_control',
    },
  ],
  [CustomerIds.simpli_vetted]: [
    {
      name: 'Landscapers',
      id: 'landscapers',
    },
    {
      name: 'Trades Contractors',
      id: 'trades_contractors',
    },
    {
      name: 'Home Inspectors',
      id: 'home_inspectors',
    },
    {
      name: 'Structural Engineers',
      id: 'structural_engineers',
    },
    {
      name: 'Pest Control',
      id: 'pest_control',
    },
  ],
}

export const findLicenseTypeById = (id: string) => {
  if (!id) return '';
  const category = licenseTypes.find(
    (c) => id.toLowerCase() === c.id.toLowerCase()
  );
  if (category) return category.name;
  return '';
};

const licenseTypes = [
  {
    name: 'General Contractors',
    id: 'general_contractors',
  },
  {
    name: 'Plumbing',
    id: 'plumbing',
  },
  {
    name: 'Electrician',
    id: 'electrician',
  },
  {
    name: 'Architecture',
    id: 'architecture',
  },
  {
    name: 'Others',
    id: 'others',
  },

  ///////
  {
    name: 'Cosmetology',
    id: 'cosmetology',
  },
  {
    name: 'Barbering',
    id: 'barbering',
  },
  {
    name: 'Others',
    id: 'others',
  },
  {
    name: 'Salon',
    id: 'salon',
  },
];
