import { useContext } from 'react';
import { AppContext, CustomerIds } from '../context/app-context';

const headerLogos = {
  [CustomerIds.freemodel]: '/images/clients-logos/freemodel.png',
  [CustomerIds.freemodel_sandbox]: '/images/clients-logos/freemodel.png',
  [CustomerIds.alhadeff_group]: '/images/clients-logos/alhadeff_group.png',
  [CustomerIds.alhadeff_group_sandbox]: '/images/clients-logos/alhadeff_group.png',
  [CustomerIds.mesh]: null,
  [CustomerIds.houzz]: null,
  [CustomerIds.houzz_sandbox]: null,
  [CustomerIds.houzz_mesh_spoof]: null,
  [CustomerIds.lisap]: null,
  [CustomerIds.mesh_sandbox]: null,
  [CustomerIds.circle]: '/images/clients-logos/circle.png',
  [CustomerIds.directory_for_contractors]: '/images/clients-logos/directory_for_contractors.png',
  [CustomerIds.simpli_vetted]: '/images/clients-logos/simpli_vetted.png',
}

export const useHeaderLogo = () => {
  const { customerId } = useContext(AppContext);
  return headerLogos[customerId]
}