import { backEndRoutes } from './backend-routes';
import axios from 'axios';
import { CustomerIds } from '../context/app-context';
import { axiosWithMeshToken } from './axios-with-mesh-token';

export interface IGetMeshUserResponse {
  customer_id: CustomerIds;
  customer_name: CustomerIds;
  email?: string | null;
  license_category_ids: string[];
  user_exists: boolean;
}
class _MeshService {
  getMeshUser = ({ meshUserId }: { meshUserId: string }) => {
    return axios
      .get<IGetMeshUserResponse>(backEndRoutes.getMeshUser(meshUserId))
      .then((res) => res.data);
  };

  validateMagicLogin = ({ didToken }: { didToken: string }) => {
    return axios
      .post<{
        access_token: string;
        refresh_token: string;
      }>(
        backEndRoutes.meshLogin(),
        {},
        {
          headers: {
            Authorization: 'Bearer ' + didToken,
          },
        }
      )
      .then((res) => ({
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      }));
  };

  userExists = ({ email }: { email: string }) => {
    return axios
      .get<{ exists: boolean }>(backEndRoutes.users.exists(), {
        params: { email },
      })
      .then((res) => res.data.exists);
  };

  createUser = ({ email }: { email: string }) => {
    return axios
      .post<{
        access_token: string;
        refresh_token: string;
      }>(backEndRoutes.users.create(), null, { params: { email } })
      .then((res) => {
        return {
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
        };
      });
  };

  getUser = () => {
    return axiosWithMeshToken
      .get<{ id: number | string; email: string }>(backEndRoutes.users.get())
      .then(({ data }) => ({ id: data?.id, email: data?.email }));
  };

  refreshToken = (refreshToken: string) => {
    return axios
      .post(
        backEndRoutes.refreshToken(),
        {},
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      )
      .then((res) => {
        return res.data?.access_token;
      });
  };

  logout = (refreshToken: string) => {
    return axios.post(
      backEndRoutes.users.logout(),
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );
  };
}

export const MeshService = new _MeshService();
