import axios from 'axios';
import {
  deleteJwtTokens,
  getRefreshToken,
  setAccessToken,
} from '../helpers/jwt-helpers';
import {
  removeFromLocalStorage,
  LocalStorageKeys,
} from '../helpers/local-storage-helpers';
import { deleteMeshAuthTokenFromAxios } from './axios-helpers';
import { MeshService } from './mesh-service.http';

const axiosWithMeshToken = axios.create();

axiosWithMeshToken.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.msg === 'Token has expired' &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        try {
          const accessToken = await MeshService.refreshToken(refreshToken);
          setAccessToken(accessToken);
          originalRequest.headers.authorization = `Bearer ${accessToken}`;
          return axiosWithMeshToken(originalRequest);
        } catch (err) {
          deleteJwtTokens();
        }
      } else {
        removeFromLocalStorage(LocalStorageKeys.meshAccessToken);
        deleteMeshAuthTokenFromAxios();
      }
    }
    return Promise.reject(error);
  }
);

export { axiosWithMeshToken };
