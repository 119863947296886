import qs from 'query-string';

const pathnameMapping = {
  '/alhadeffgroupstg': 'c193f651-8e5f-4586-a2a8-9543bd34e832',
  '/alhadeffgroup': '968095ee-836e-4ff1-8562-c9595dbd6c0b',
  '/freemodelstg': 'e0acf4cc-3636-4f34-a28b-03cde5cd9512',
  '/freemodel': '480c8c26-593e-4082-99c5-5b8ad908297d',
  '/circle': '95ded6c2-1dc5-49e9-b3e3-0bdc1ddb88bc',
  '/directoryforcontractors': '7f05c92e-ca78-424c-9ab5-02da97d1d384',
  '/simplivetted': '0dfb8213-da1b-42c3-866f-52aa14eb1615',
}
const getMeshUserIdFromPath = (pathname: string) => {
  let result = null;
  Object.entries(pathnameMapping).find(([key, value]) => {
    if (key === pathname) {
      result = value;
      return true;
    }
    return false;
  });
  return result;
}


export const getAppConfigFromQS = (): {
  meshUserId: string | null;
  origin: string | null;
  pathname: string | null;
} => {
  let { origin, meshUserId: meshUserIdFromQS } = qs.parse(window.location.search);

  if (Array.isArray(meshUserIdFromQS)) meshUserIdFromQS = null;
  if (Array.isArray(origin)) origin = null;

  const meshUserIdFromPath = getMeshUserIdFromPath(window.location.pathname)
  const meshUserId = meshUserIdFromQS || meshUserIdFromPath || null;
  return {
    origin,
    meshUserId,
    pathname: window.location.pathname.replace(/^\//, ''),
  };
};
