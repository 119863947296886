import styles from './footer.module.scss';
import { ReactComponent as MeshLogo } from '../../icons/mesh-logo.svg';
import { TestLogout } from '../test-logout';
import { isLocalhost } from '../../helpers/env-checkers';

const Footer: React.FC<{}> = ({ children }) => {
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.text}>Verifications powered by</div>{' '}
        <a target="_blank" href="https://www.mesh.id/" rel="noreferrer">
          <MeshLogo />
        </a>
      </footer>
      {isLocalhost() && <TestLogout />}
    </>
  );
};

export default Footer;
