import { magic } from '../lib/magic';

class _MagicService {
  logout = () => {
    return magic.user.logout();
  };
  login = () => {
    return magic.user.isLoggedIn();
  };
  getIdToken = () => {
    return magic.user.getIdToken();
  };
  getMetadata = () => {
    return magic.user.getMetadata();
  };
}

export const MagicService = new _MagicService();
