import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import Layout from '../../components/layout/layout';
import {
  IVerifyParams,
  LicensesService,
} from '../../services/licenses-service.http';
import { AppContext, CustomerIds } from '../../context/app-context';
import { UserContext } from '../../context/user-context/user-context';
import { VerifyingLoader } from '../../components/form-result-screens/verifying-loader/verifying-loader';
import { Congratulations } from '../../components/form-result-screens/congrats/congratulations';
import { PendingScreen } from '../../components/form-result-screens/pending/pending';
import { FoundAndInactiveScreen } from '../../components/form-result-screens/fount-and-inactive/found-and-inactive';
import { VerifyForm } from '../../components/form-result-screens/verify-form/verify-form';
import { useForm } from 'react-hook-form';
import { StateNotSupported } from '../../components/form-result-screens/state-not-supported/state-not-supported';
import { getStateNameForCode } from '../../data/state-data';
import { IServerResult } from './types';
import { findLicenseTypeById } from '../../data/categories';
import { useToggle } from '../../hooks/use-toggle';
import moment from 'moment';
import { isLocalhost } from '../../helpers/env-checkers';
import { MeshService } from '../../services/mesh-service.http';
import {
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '../../helpers/jwt-helpers';
import { useHistory } from 'react-router';
import { routes } from '../../routes/routes';
import { LicenseNotFound } from '../../components/form-result-screens/license-not-found/license-not-found';
import {
  ExitPageStatuses,
  notifyParentonLicenseFormSubmissionResult,
} from '../../helpers/notify-parent';
import { captureError } from '../../lib/sentry';
import Feedback from '../../components/feedback/feedback';
import { addProperties, logEvent } from '../../lib/analytics';

enum ActiveContent {
  'formPage' = 'formPage',
  'verifyingPage' = 'verifyingPage',
  'congratsPage' = 'congratsPage',
  'pendingPage' = 'pendingPage',
  'foundInactivePage' = 'foundInactivePage',
  'stateNotSupported' = 'stateNotSupported',
  'notFoundLicensePage' = 'notFoundLicensePage',
}

const ActiveContentResultStatusMapping = {
  [ActiveContent.congratsPage]: ExitPageStatuses.license_found_and_active,
  [ActiveContent.pendingPage]: ExitPageStatuses.pending,
  [ActiveContent.foundInactivePage]: ExitPageStatuses.license_inactive,
  [ActiveContent.stateNotSupported]: ExitPageStatuses.state_not_supported,
  [ActiveContent.notFoundLicensePage]: ExitPageStatuses.license_not_found,
};

export type IVerifyingStatuses = null | 'verifying' | 'completed';

const STATUS_CHECK_DELAY = 500;
const STATUS_CHECK_REPEAT = 8;

export const FormPage: React.FC<{}> = () => {
  const { meshUserId, isProBeauty, origin, customerId } = useContext(AppContext);
  const { user } = useContext(UserContext);
  const {
    isActive: isSubmitDisabled,
    setActive: disableSubmit,
    setInActive: enableSubmit,
  } = useToggle(false);
  const history = useHistory();

  const [serverResult, setServerResult] = useState<IServerResult | null>(null);

  const [verifyingStatus, setVerifyingStatus] =
    useState<IVerifyingStatuses>('verifying');

  const [serverErrorMsg, setServerErrorMsg] = useState<string | null>(null);

  const [activeContent, setActiveContent] = useState<ActiveContent>(
    ActiveContent.formPage
  );

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    trigger,
    clearErrors,
  } = useForm<IVerifyParams>();

  const setSomethingWentWrong = () => {
    setServerErrorMsg(
      'Something went wrong, please try again or contact us at support@mesh.id'
    );
  };

  const licenseSubmitErrorHandler = (err: any) => {
    enableSubmit();
    const errorMsg = err?.response?.data?.message;
    if (
      errorMsg &&
      typeof errorMsg === 'string' &&
      errorMsg.indexOf('not enabled') !== -1
    ) {
      setActiveContent(ActiveContent.stateNotSupported);
      return;
    }
    if (
      typeof errorMsg === 'string' &&
      errorMsg.indexOf('not enabled') !== -1
    ) {
      setActiveContent(ActiveContent.stateNotSupported);
    }
    captureError(err);
    if (err?.response?.status && err?.response?.status === 401) {
      history.push(routes.login(), {
        errorMsg: 'Your session has expired. Please login again.',
      });
      return;
    }
    setActiveContent(ActiveContent.formPage);
    if (!errorMsg) {
      setSomethingWentWrong();
      return;
    }
    if (typeof errorMsg === 'string') {
      setServerErrorMsg(errorMsg);
    } else {
      setServerErrorMsg(JSON.stringify(errorMsg));
    }
  };

  const resetForm = () => {
    setServerResult(null);
    reset({
      entity_type: '',
      license_category_id: '',
      license_board_state: '',
      first_name: '',
      last_name: '',
      business_name: '',
      license_number: '',
      expiration_date: '',
      additional_information: '',
    });
  };

  const submitNewForm = () => {
    resetForm();
    setActiveContent(ActiveContent.formPage);
  };

  // FOR EASY TESTING on local
  useEffect(() => {
    (window as any).fillActiveForm = () => {
      const mockData = {
        // additional_information: '',
        // expiration_date: '2022-09-16',
        // first_name: 'Nunez',
        // last_name: 'Ramirez',
        // license_board_state: 'WA',
        // license_category_id: 'home_service',
        // entity_type: 'individual',
        // license_type: 'general_contractors',
        // // license_number: 'TAPPSE*812QD', //active
        // license_number: 'NORTHTS842KW', //non-active

        // CA Data:
        additional_information: '',
        expiration_date: '2022-09-16',
        first_name: 'Mary',
        last_name: 'Smith',
        business_name: 'A A Electric',
        license_board_state: 'CA',
        license_category_id: 'home_service',
        entity_type: 'business',
        license_type: 'general_contractors',
        // license_number: 'TAPPSE*812QD', //active
        license_number: '968747', //non-active
      };
      for (let key in mockData) {
        // @ts-ignore
        setValue(key, mockData[key]);
      }
    };
    (window as any).submitNewForm = submitNewForm;
    if (isLocalhost()) {
      (window as any).fillActiveForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // ------------

  const statusCheckCount = useRef<number>(0);

  const checkStatusOnServer = (verificationId: string) => {
    setVerifyingStatus('verifying');
    setActiveContent(ActiveContent.verifyingPage);
    statusCheckCount.current++;
    LicensesService.checkStatus(verificationId, {
      meshUserId,
    })
      .then(({ data }) => {
        if (data.verification_status === 'finished') {
          setServerResult(data.result);
          if (
            data.result?.found_license?.license?.normalized_status === 'active'
          ) {
            setVerifyingStatus('completed');
            logEvent('verification_completed', { branch: 'congrats' })
            setTimeout(() => {
              setActiveContent(ActiveContent.congratsPage);
            }, 2000);
          } else if (data.result?.license_search_status === 'not_found') {
            logEvent('verification_completed', { branch: 'not_found' })
            setActiveContent(ActiveContent.notFoundLicensePage);
          } else {
            logEvent('verification_completed', { branch: 'inactive' })
            setActiveContent(ActiveContent.foundInactivePage);
          }
        } else if (data.entered_internal_review) {
          setActiveContent(ActiveContent.pendingPage);
        } else if (data.verification_status === 'failed') {
          logEvent('verification_completed', { branch: 'error' })
          setActiveContent(ActiveContent.formPage);
          setSomethingWentWrong();
        } else if (data.verification_status === 'pending') {
          if (statusCheckCount.current <= STATUS_CHECK_REPEAT) {
            setTimeout(
              () => checkStatusOnServer(verificationId),
              STATUS_CHECK_DELAY
            );
          } else {
            logEvent('verification_completed', { branch: 'pending' })
            setServerResult(data.result);
            setActiveContent(ActiveContent.pendingPage);
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const _allCovered: never = data.verification_status;
        }
      })
      .catch(licenseSubmitErrorHandler);
  };

  const submitVerifyForm = async (data: IVerifyParams) => {
    disableSubmit();
    if (!getRefreshToken()) {
      try {
        const userExists = await MeshService.userExists({
          email: user?.email as string,
        });
        if (!userExists) {
          await MeshService.createUser({ email: user?.email as string }).then(
            ({ accessToken, refreshToken }) => {
              setRefreshToken(refreshToken);
              setAccessToken(accessToken);
            }
          );
        } else {
          history.push(routes.login(), {
            errorMsg: 'Please login to submit the form',
          });
          return;
        }
      } catch (err: any) {
        captureError(err);
        history.push(routes.login(), {
          errorMsg:
            err && typeof err?.response?.data?.message
              ? err?.response?.data?.message
              : null,
        });
        return;
      }
    }

    let license_category_id =
      data.license_type === 'architecture' ? 'architecture' : 'home_services';
    if (isProBeauty) {
      license_category_id = 'professional_beauty';
    }

    addProperties({
      license_type: data.license_category_id,
      license_region: data.license_board_state,
    })

    logEvent('verification_submitted')
    LicensesService.verify(
      {
        ...data,
        expiration_date: null,
        license_category_id,
      },
      {
        meshUserId,
      }
    )
      .then(({ data }) => {
        statusCheckCount.current = 0;
        if (data.verification_status === 'finished') {
          setServerResult(data.result);
          if (
            data.result?.found_license?.license?.normalized_status === 'active'
          ) {
            setVerifyingStatus('completed');
            logEvent('verification_completed', { branch: 'congrats' })
            setTimeout(() => {
              setActiveContent(ActiveContent.congratsPage);
            }, 2000);
          } else if (data.result?.license_search_status === 'not_found') {
            logEvent('verification_completed', { branch: 'not_found' })
            setActiveContent(ActiveContent.notFoundLicensePage);
          } else {
            logEvent('verification_completed', { branch: 'inactive' })
            setActiveContent(ActiveContent.foundInactivePage);
          }
        } else if (data.verification_status === 'failed') {
          logEvent('verification_completed', { branch: 'error' })
          setSomethingWentWrong();
        } else if (data.verification_status === 'pending') {
          checkStatusOnServer(data.verification_id);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const _allCovered: never = data.verification_status;
        }
        enableSubmit();
      })
      .catch(licenseSubmitErrorHandler);
  };

  useEffect(() => {
    if (
      activeContent !== ActiveContent.verifyingPage &&
      activeContent !== ActiveContent.formPage
    ) {
      const result = ActiveContentResultStatusMapping[activeContent];
      if (result) {
        notifyParentonLicenseFormSubmissionResult({
          result: ActiveContentResultStatusMapping[activeContent],
          origin,
        });
      } else {
        captureError(new Error('ActiveContentResultStatusMapping not found'), {
          extra: {
            msg: 'No Mapping For result and exit status',
            result,
            activeContent,
          },
        });
      }
    }
  }, [activeContent, origin]);

  const getActiveContent = () => {
    switch (activeContent) {
      case ActiveContent.verifyingPage: {
        return <VerifyingLoader verifyingStatus={verifyingStatus} />;
      }
      case ActiveContent.congratsPage: {
        return (<Fragment>
          <Congratulations
            feedbackParams={{
              custom: {
                source: 'inline',
                email: user?.email,
                experience: ExitPageStatuses.license_found_and_active,
                state: getValues('license_board_state'),
                licenseType: getValues('license_type'),
                customer: customerId,
              },
              user: {
                email: user?.email,
              },
            }} />
        </Fragment>);
      }
      case ActiveContent.pendingPage: {
        return (
          <Fragment>
            <PendingScreen
              license={{
                license_number: getValues('license_number'),
                license_board_state: getValues('license_board_state'),
                license_type: findLicenseTypeById(
                  getValues('license_type') as string
                ),
              }}
              feedbackParams={{
                custom: {
                  source: 'inline',
                  email: user?.email,
                  experience: ExitPageStatuses.pending,
                  state: getValues('license_board_state'),
                  licenseType: getValues('license_type'),
                  customer: customerId,
                },
                user: {
                  email: user?.email,
                },
              }}
            />
          </Fragment>
        );
      }
      case ActiveContent.stateNotSupported: {
        return (
          <Fragment>
            <StateNotSupported
              licenseType={findLicenseTypeById(getValues('license_type'))}
              state={
                getStateNameForCode(getValues('license_board_state')) as string
              }
              feedbackParams={{
                custom: {
                  source: 'inline',
                  email: user?.email,
                  experience: ExitPageStatuses.state_not_supported,
                  state: getValues('license_board_state'),
                  licenseType: getValues('license_type'),
                  customer: customerId,
                },
                user: {
                  email: user?.email,
                },
              }}
            />
          </Fragment>
        );
      }
      case ActiveContent.notFoundLicensePage: {
        return (
          <Fragment>
            <LicenseNotFound
              license={{
                state: getStateNameForCode(
                  getValues('license_board_state')
                ) as string,
                license_number: getValues('license_number') as string,
                first_name: getValues('first_name') as string,
                last_name: getValues('last_name') as string,
                license_type: findLicenseTypeById(
                  getValues('license_type') as string
                ),
              }}
              submitNew={submitNewForm}
            />
          </Fragment>
        );
      }
      case ActiveContent.foundInactivePage: {
        return (
          <Fragment>
            <FoundAndInactiveScreen
              license={
                serverResult?.found_license
                  ?.license as IServerResult['found_license']['license']
              }
              submitNew={submitNewForm}
            />
          </Fragment>
        );
      }
      case ActiveContent.formPage: {
        return (
          <VerifyForm
            setValue={setValue}
            clearErrors={clearErrors}
            trigger={trigger}
            serverErrorMsg={serverErrorMsg}
            control={control}
            watch={watch}
            isSubmitDisabled={isSubmitDisabled}
            onSubmit={handleSubmit(submitVerifyForm)}
          />
        );
      }
      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _allCovered: never = activeContent;
      }
    }
  };

  return <Layout>{getActiveContent()}</Layout>;
};
