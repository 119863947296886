import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../context/user-context/user-context';
import { getQueryParam } from '../helpers/get-query-param';
import { getAccessToken, getRefreshToken } from '../helpers/jwt-helpers';
import { captureError } from '../lib/sentry';
import { routes } from '../routes/routes';
import { MeshService } from '../services/mesh-service.http';

export const useLoginHelpers = () => {
  const history = useHistory();
  const { setUser, logout } = useContext(UserContext);

  const redirectPage = useMemo(() => {
    const redirect = getQueryParam('redirect');
    const isForSubscribe =
      redirect === 'subscribe' || redirect === 'unsubscribe';
    return isForSubscribe ? redirect : false;
  }, []);

  const redirectToSuccessPage = useCallback(() => {
    if (redirectPage) {
      history.push(`/${redirectPage}`);
    } else {
      history.push(routes.formPage());
    }
  }, [redirectPage, history]);

  const redirectToLogin = useCallback(() => {
    if (redirectPage) {
      history.push(routes.login() + `?redirect=${redirectPage}`);
    } else {
      history.push(routes.login());
    }
  }, [redirectPage, history]);

  const tryLocalLogin = useCallback(
    async (clientPreDefinedMail: string | null | undefined) => {
      if (!getAccessToken() || !getRefreshToken()) return false;
      // Temporarily removing all local login functionality
      // This will be reworked in
      // https://meshid.atlassian.net/browse/DV-23

      // try {
      //   const { id, email } = await MeshService.getUser();
      //   if (!clientPreDefinedMail || email === clientPreDefinedMail) {
      //     setUser({ email, id });
      //     return true;
      //   }
      // } catch (err: any) {
      //   if (
      //     !(
      //       err?.response?.status === 401 &&
      //       err?.response?.data?.msg === 'Token has expired'
      //     )
      //   ) {
      //     captureError(err);
      //   }
      // }
      logout();
      return false;
    },
    [setUser, logout]
  );

  return {
    isForSubscription: !!redirectPage,
    redirectPage,
    tryLocalLogin,
    redirectToSuccessPage,
    redirectToLogin,
  };
};
