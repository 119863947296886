import styles from './header.module.scss';

const Header: React.FC<{}> = ({ children }) => {
  return (
    <header className={styles.header}>
      <div className={styles.inner}>{children}</div>
    </header>
  );
};

export default Header;
