export enum ExitPageStatuses {
  license_found_and_active = 'license_found_and_active',
  pending = 'pending',
  license_inactive = 'license_inactive',
  state_not_supported = 'state_not_supported',
  license_not_found = 'license_not_found',
}

export const notifyParentOnFinish = ({
  exitPage,
  payload,
  origin,
}: {
  exitPage: ExitPageStatuses;
  payload: Record<string, any> | any;
  origin: string;
}) => {
  global.parent.postMessage(
    {
      eventName: 'onFinish',
      payload: { exitPage },
    },
    origin
  );
};

export const notifyParentonLicenseFormSubmissionResult = ({
  result,
  origin,
}: {
  origin: string;
  result: ExitPageStatuses;
}) => {
  global.parent.postMessage(
    {
      eventName: 'onLicenseFormSubmissionResult',
      payload: { result },
    },
    origin
  );
};
