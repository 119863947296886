import { axiosWithMeshToken } from './axios-with-mesh-token';

export const setMeshAuthTokenOnAxios = (token: string) => {
  axiosWithMeshToken.defaults.headers.common[
    'authorization'
  ] = `Bearer ${token}`;
};

export const deleteMeshAuthTokenFromAxios = () => {
  delete axiosWithMeshToken.defaults.headers.common['authorization'];
};
