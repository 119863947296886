import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { IVerifyingStatuses } from '../../../pages/form/form-page';
import styles from './styles.module.scss';
import { ReactComponent as CheckIcon } from '../../../icons/check-solid.svg';

interface Props {
  verifyingStatus: IVerifyingStatuses;
}
export const VerifyingLoader: React.FC<Props> = ({ verifyingStatus }) => {
  return (
    <div className={styles.verifyingPage}>
      <div className={styles.loader}>
        {verifyingStatus === 'verifying' ? (
          <CircularProgress
            // thickness={4}
            size={180}
            className={styles['MuiCircularProgress-colorPrimary']}
          />
        ) : (
          <div className={styles.success}>
            <CheckIcon />
          </div>
        )}
      </div>
      <h4>Verifying</h4>
    </div>
  );
};
