import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../context/user-context/user-context';
import { routes } from '../routes/routes';

export const TestLogout = () => {
  const { user, logout } = useContext(UserContext);
  const history = useHistory();
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        bottom: 0,
        display: 'flex',
        margin: '0',
      }}
    >
      {user && (
        <button
          className="btn btn-sm"
          color="warning"
          onClick={() => {
            logout();
            history.push(routes.pageResolver());
          }}
        >
          <b>Logout</b>
        </button>
      )}
    </div>
  );
};
