import { useContext } from 'react';
import { AppContext, CustomerIds } from '../context/app-context';

const displayNames = {
  [CustomerIds.freemodel]: 'Freemodel',
  [CustomerIds.freemodel_sandbox]: 'Freemodel',
  [CustomerIds.alhadeff_group]: 'Alhadeff Group',
  [CustomerIds.alhadeff_group_sandbox]: 'Alhadeff Group',
  [CustomerIds.mesh]: 'Mesh',
  [CustomerIds.houzz]: 'Houzz',
  [CustomerIds.houzz_sandbox]: 'Houzz',
  [CustomerIds.houzz_mesh_spoof]: 'Houzz',
  [CustomerIds.lisap]: 'Lisap',
  [CustomerIds.mesh_sandbox]: 'Mesh',
  [CustomerIds.circle]: 'Circle',
  [CustomerIds.directory_for_contractors]: 'Directory For Contractors',
  [CustomerIds.simpli_vetted]: 'SimpliVetted',
}

export const useDisplayName = () => {
  const { customerId } = useContext(AppContext);
  return displayNames[customerId]
}